import {
  createVNode as _createVNode,
  openBlock as _openBlock,
  createBlock as _createBlock,
} from "vue";

export default function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 12 12",
        fill: "currentColor",
      },
      [
        _createVNode("path", {
          "fill-rule": "evenodd",
          d: "M10.875 2.99806H1.785C1.67643 3.01907 1.56456 3.01581 1.4574 2.98852C1.35023 2.96123 1.25043 2.91058 1.16514 2.84019C1.07985 2.76981 1.01118 2.68143 0.964041 2.5814C0.916906 2.48136 0.892476 2.37214 0.8925 2.26156V2.24731L10.5 1.87306V0.980559C10.5157 0.847397 10.5009 0.712417 10.4567 0.585812C10.4126 0.459207 10.3402 0.344287 10.2452 0.249732C10.1501 0.155176 10.0348 0.0834555 9.90797 0.039987C9.78113 -0.00348147 9.64608 -0.0175618 9.513 -0.00119071L0.9825 1.38556C0.694599 1.49184 0.44613 1.6837 0.270491 1.93536C0.0948513 2.18702 0.00045873 2.48642 0 2.79331L0 10.8723C0 11.1707 0.118526 11.4568 0.329505 11.6678C0.540483 11.8788 0.826631 11.9973 1.125 11.9973H10.875C11.1734 11.9973 11.4595 11.8788 11.6705 11.6678C11.8815 11.4568 12 11.1707 12 10.8723V4.11481L12.0007 4.08406C12.0007 3.79564 11.8862 3.51903 11.6822 3.31508C11.4783 3.11113 11.2017 2.99656 10.9132 2.99656L10.8735 2.99731L10.875 2.99806ZM9.75 8.24806C9.45153 8.24796 9.16533 8.1293 8.95435 7.91818C8.74337 7.70706 8.6249 7.42078 8.625 7.12231C8.6251 6.82384 8.74376 6.53764 8.95488 6.32666C9.166 6.11568 9.45228 5.99721 9.75075 5.99731C9.89854 5.99736 10.0449 6.02652 10.1814 6.08312C10.3179 6.13972 10.4419 6.22265 10.5464 6.32719C10.6509 6.43172 10.7337 6.55581 10.7902 6.69237C10.8467 6.82892 10.8758 6.97527 10.8758 7.12306C10.8757 7.27084 10.8465 7.41717 10.7899 7.55369C10.7333 7.69021 10.6504 7.81424 10.5459 7.91871C10.4413 8.02318 10.3172 8.10603 10.1807 8.16254C10.0441 8.21905 9.89779 8.24811 9.75 8.24806Z",
          "clip-rule": "evenodd",
        }),
      ]
    )
  );
}
