<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="
          inline-flex
          justify-center
          items-center
          w-full
          px-2
          py-2
          whitespace-nowrap
          text-base
          font-medium
          opacity-60
          hover:opacity-100
        "
      >
        <UserCircleIcon class="h-6 w-6" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="
          absolute
          right-0
          w-48
          mt-2
          origin-top-right
          bg-white
          rounded-md
          shadow-lg
          ring-1 ring-black ring-opacity-5
          focus:outline-none
          z-10
        "
      >
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button
              @click="logoutUser"
              :class="[
                active ? 'bg-brand text-white' : 'text-gray-900',
                'group flex rounded-md items-center w-full px-2 py-3 ',
              ]"
              class="
                whitespace-nowrap
                text-base
                font-medium
                opacity-60
                hover:opacity-100
              "
            >
              {{ $t("logout") }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { UserCircleIcon } from "@heroicons/vue/solid";

export default {
  name: "ProfileOptionsComponent",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    UserCircleIcon,
  },
  methods: {
    logoutUser() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped></style>
