export default [
  {
    name: "Products",
    i18n: "products",
    url: "/products",
  },
  {
    name: "Suppliers",
    i18n: "suppliers",
    url: "/suppliers",
  },
  {
    name: "Inventory",
    i18n: "inventory",
    url: "/inventory",
  },
  {
    name: "Customers",
    i18n: "customers",
    url: "/customers",
  },
  {
    name: "Wallet",
    i18n: "wallet",
    url: "/wallet",
  },
  {
    name: "Reports",
    i18n: "reports",
    children: [
      {
        name: "Sales Report",
        i18n: "salesReport",
        url: "/reports/sales",
      },
      {
        name: "Recharge Summary",
        i18n: "rechargeSummary",
        url: "/reports/recharge-summary",
      },
    ],
  },
  {
    name: "Reclamation",
    i18n: "reclamation",
    url: "/reclamation",
  },
];
