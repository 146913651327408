<template>
  <div class="flex flex-col bg-white mt-8">
    <div class="flex flex-row justify-end py-4">
      <p class="px-4 cursor-pointer">
        {{ $t("copyright") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped></style>
