<template>
  <div class="relative">
    <div class="flex flex-col bg-background min-h-screen justify-between">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "MainNoAuth",
  components: {},
};
</script>

<style scoped></style>
